<template>
  <div class="card" v-if="authenticated" style="width: 100%; height: 100%">
    <div class="row m-3">
      <div class="col p-0">
        <button class="btn btn-secondary" @click="updateAgent">
          Update agent
        </button>
        <button class="btn btn-secondary" @click="resyncAll">
          Set Resync all data
        </button>

        <button class="btn btn-secondary" @click="restartAgent">
          Restart Agent
        </button>

        <button class="btn btn-secondary" @click="generateMonthlyReport">
          Monthly Report
        </button>
      </div>
    </div>

    <div class="row m-3">
      <!-- <pre>{{code}}</pre> -->
      <prism-editor
        class="my-editor"
        v-model="code"
        :highlight="highlighter"
        rows="5"
        :line-numbers="true"
      ></prism-editor>
    </div>

    <div class="row d-flex justify-content-end m-3">
      <button class="btn col-2 btn-sm btn-secondary me-3">Clear</button>
      <button
        class="btn col-2 btn-sm btn-primary me-3"
        @click="submitSQL"
        :disabled="loading"
      >
        Submit
      </button>
    </div>

    <div class="row m-3 text-center" v-if="loading">
      <div class="loader"></div>
    </div>

    <div class="row" v-if="rowData">
      <div class="col-12 text-end">
        <button class="btn btn-secondary text-left" @click="copyCurrentRow">
              Copy Selected Row
        </button>
        <i
          class="fa-solid fa-file-csv cursor-pointer fs-30-px mx-5"
          @click="exportAsCSV"
          title="Download results as Excel"
        ></i>
      </div>
    </div>
    <div
      class="card-body row m-3 p-0"
      style="width: 100%; height: 100%"
      v-if="rowData"
    >
      <div class="table-responsive row p-0 m-0">
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :rowHeight="35"
            :enableRangeSelection="true"
            :allowContextMenuWithControlKey="true"
            rowSelection="multiple"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            @getContextMenuItems="getContextMenuItems"
            class="ag-theme-balham p-0"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
import ApiService from "@/core/services/ApiService";
import { AgGridVue } from "ag-grid-vue3";
import { XMLParser } from "fast-xml-parser";
import { codes } from "@/Codes/Codes";
import { useToast } from "vue-toastification";

export default {
  components: {
    AgGridVue,
    PrismEditor,
  },
  data: () => ({
    toast: useToast(),
    code: "SELECT 1 as hello FOR XML PATH ('row')",
    loading: false,
    authenticated: true,
    sqlRes: null,
    //ag-grid
    defaultColDef: null,
    columnDefs: [],
    rowData: null,
    gridApi: null,
    columnApi: null,
    api: null,
    hardCoded: null,
  }),
  methods: {
    async generateMonthlyReport(){
      try {
        this.loading = true;
        let x = await ApiService.postData(
          `EmailReports/RnaMonthly?clinicId=${this.$store.getters.currentUser.currentClinic.clinicId}`,
          {},
          {}
        );
        if (x.status == 200) {
          this.loading = false;
          this.$root.showMessage("Generate report command sent");
        } else {
          this.toast.error(codes.error);
        }
      } catch (e) {
        this.toast.error(codes.error);
        throw e;
      }

    },
    getContextMenuItems(params) {
      console.log("yes");
      return ["copy", "separator", "chartRange"];
    },

    async updateAgent() {
      try {
        this.loading = true;
        let x = await ApiService.postData(
          "Clinic/CheckAgentForUpdates",
          {},
          {}
        );
        if (x.status == 200) {
          this.loading = false;
          this.$root.showMessage("Update command sent");
        } else {
          this.toast.error(codes.error);
        }
      } catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
    async resyncAll() {
      try {
        this.loading = true;
        let x = await ApiService.postData(
          "Clinic/SetResyncAll",
          {},
          {}
        );
        if (x.status == 200) {
          this.loading = false;
          this.$root.showMessage("SetResyncAll command sent");
        } else {
          this.toast.error(codes.error);
        }
      } catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
    async restartAgent() {
      try {
        this.loading = true;
        let x = await ApiService.postData(
          "Clinic/RestartRNAAgentService",
          {},
          {}
        );
        if (x.status == 200) {
          this.loading = false;
          this.$root.showMessage("Restart agent command sent");
        } else {
          this.toast.error(codes.error);
        }
      } catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
    async submitSQL() {
      try {
        const options = {
          ignoreAttributes: false,
        };

        this.rowData = null;
        this.defaultColDef = null;
        this.columnDefs = [];
        this.loading = true;
        this.sqlRes = null;
        let results = await ApiService.postData(
          "Clinic/SubmitCommand",
          { sql: this.code },
          {}
        );
        if (results.status !== 200) {
          this.toast.error(codes.error);
        }
        this.sqlRes = results.data;
        // this.sqlRes = this.hardCoded;
        this.loading = false;

        if (this.sqlRes) {
          this.defaultColDef = {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            suppressMenu: true,
            filterParams: {
              buttons: ["apply", "reset"],
              closeOnApply: true,
              suppressAndOrCondition: true,
            },
          };

          if (Array.isArray(this.sqlRes)) {
            if (this.sqlRes.length === 0) {            
                this.toast.warning("No data found");
                return;
            }

            let cols = Object.keys(this.sqlRes[0]);
            cols.forEach((element) => {
              this.columnDefs.push({
                field: element,
                filter: true,
                headerName: element,
              });
            });

            this.rowData = this.sqlRes;
          } else {
            this.toast.error(this.sqlRes);            
          }
        }

        console.log(results);
      } catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    exportAsCSV() {
      this.api.exportDataAsCsv();
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    copyCurrentRow() {
      var selectRow =  this.gridApi.getSelectedRows();
      if(selectRow.length > 0) {
        navigator.clipboard.writeText(JSON.stringify(selectRow));
      }
      else {
        navigator.clipboard.writeText(JSON.stringify('No row is selected'));
      }
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
  },
  mounted() {
    setCurrentPageTitle("SQL Explorer");
  },
};
</script>

<style lang="scss" scoped>
// required class
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  resize: vertical;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

// not required:
.height-300 {
  height: 300px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>